import React, { useEffect, useState } from 'react';
import { getContent } from '../core/helpers';
import ContentTmpl from './ContentTmpl';

export default function ServicesPage(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [subMenus, setsubMenus] = useState([]);
  const [templates, settemplates] = useState([]);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/services.json').then((x) => {
      setcontent(x);
      // start ordering the template design
      let tmp = [],
        menuSet = [];
      Object.values(x).forEach((y) => {
        tmp.push({ type: 'header', value: y?.title });
        tmp.push({ type: 'para', value: y?.description });
        menuSet.push({ title: y?.title, link: `#${y?.title}` });
        if (Array.isArray(y?.services)) {
          y?.services?.forEach((z) => {
            tmp.push({ type: 'para', value: '- ' + z });
          });
        } else {
          Object.keys(y?.services)?.forEach((z) => {
            tmp.push({ type: 'para', value: '- ' + z });
            y?.services[z].forEach((z) => {
              tmp.push({ type: 'para', value: '-- ' + z });
            });
          });
        }
      });

      settemplates(tmp);
      setsubMenus(menuSet);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return <ContentTmpl leftMenu={subMenus} title="Our Services" contents={templates} />;
}
