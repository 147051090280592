import React, { useEffect, useState } from 'react';
import { getContent } from '../core/helpers';
import ContentTmpl from './ContentTmpl';
import People from './People';

export default function OurFirm(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/aboutus.json').then((x) => {
      setcontent(x);
      console.log(x);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return (
    <ContentTmpl
      leftMenu={[
        { title: 'Our Firm', link: '/our-firm' },
        { title: 'Our Team', link: '/our-team' },
        { title: 'Our Mission', link: '#Our Mission' },
        { title: 'Our Vision', link: '#Our Vision' },
        { title: 'About PKF International', link: '#About PKF International' },
      ]}
      title="Our Firm"
      contents={[
        { type: 'header', value: content?.about?.title1 },
        { type: 'para2', value: content?.about?.subhead1 },
        { type: 'para', value: content?.about?.para1 },
        { type: 'para', value: content?.about?.para2 },
        { type: 'para', value: content?.about?.para3 },
        { type: 'para', value: content?.about?.para4 },
        // End of about PKF Firm
        { type: 'para2', value: content?.mission?.title1 },
        { type: 'para', value: content?.mission?.para1 },
        // End of Our Mission
        { type: 'para2', value: content['Our Vision']?.title1 },
        { type: 'para', value: content['Our Vision']?.para1 },
        // End of Our Vision
        { type: 'para2', value: content['About PKF International']?.title1 },
        { type: 'para', value: content['About PKF International']?.para1 },
        // End of about international
      ]}
      basicc={<People />}
    />
  );
}
