import React, { useEffect, useState } from "react";
import { getContent } from "../core/helpers";
import ContentTmpl from "./ContentTmpl";
import People from "./People";

export default function OurTeam(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent("/assets/aboutus.json").then((x) => {
      setcontent(x);
      console.log(x);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return (
    <ContentTmpl
      leftMenu={[
        { title: "Our Firm", link: "/our-firm" },
        { title: "Our Team", link: "/our-team" },
      ]}
      title="Our Team"
      basicc={<People />}
    />
  );
}
