import React from 'react';
import Breadcrumbs from '../components/extra/BreadCrumb';

/**
 * ContentTmpl Dynamic
 * 1- leftMenu Array
 * 2- Title of Page
 * 3- Contents array of Objects
 *  [{type: 'para|image|header|header2',value:'string', title:'optional'}]
 * 4- Header "image" image source
 * @param {object} props
 * @returns
 */
export default function ContentTmpl(props) {
  const { leftMenu, title, contents, image, desc, basicc } = props;
  return (
    <main>
      <section
        className="heading headerMedium Blue"
        style={{
          backgroundImage: `url(${image ? image : '/media/people-690810.jpg'})`,
          backgroundRepeat: ' no-repeat',
          backgroundSize: ' cover',
        }}
      >
        <div className="container">
          <h2>{title}</h2>
        </div>
      </section>
      <section className="section">
        <div className="container text-start">
          <div className="row">
            {/* {Object.keys(leftMenu)?.length > 0 ? (
              <div className="col-md-3">
                <nav className="subnav sticky-top  pt-5 pt-5 ">
                  <ul>
                    {Object.keys(leftMenu)?.map((x) => {
                      return (
                        <li>
                          {" "}
                          <a
                            key={title + leftMenu[x]?.title + "-nav"}
                            href={leftMenu[x]?.link}
                            title={leftMenu[x]?.title}
                          >
                            {leftMenu[x]?.title}
                          </a>{" "}
                        </li>
                      );
                    })}
                  </ul>
                </nav>{" "}
              </div>
            ) : (
              []
            )} */}
            {/* <div className={Object.keys(leftMenu)?.length > 0 ? 'col-md-9' : 'col-md-12 p-3'}> */}
            <div className="col-md-9 m-auto">
              <article>
                <div className="crumbs mt-md-0 mt-sm-4">
                  <Breadcrumbs />
                </div>
                {/* <h1>{title}</h1> */}
                {desc ? desc : ''}
                <div className="umb-grid" id="gridContent">
                  <div>
                    {' '}
                    <div className="row clearfix">
                      <div className="col-md-12">
                        {Object.keys(contents)?.length > 0 ? (
                          <div>
                            {Object.keys(contents)?.map((x) => {
                              console.log(contents[x]?.type);
                              switch (contents[x]?.type) {
                                case 'header':
                                  return (
                                    <h2 className="mt-2 mb-2" id={contents[x]?.value}>
                                      {contents[x]?.value}
                                    </h2>
                                  );
                                case 'header2':
                                  return (
                                    <h3 className="mt-1 mb-1" id={contents[x]?.value}>
                                      {contents[x]?.value}
                                    </h3>
                                  );
                                case 'para':
                                  return <p id={contents[x]?.value}>{contents[x]?.value}</p>;
                                case 'para2':
                                  return (
                                    <p id={contents[x]?.value}>
                                      <b>{contents[x]?.value}</b>
                                    </p>
                                  );
                                case 'image':
                                  return (
                                    <img
                                      id={contents[x]?.value}
                                      src={contents[x]?.value}
                                      alt={contents[x]?.title}
                                    />
                                  );
                                default:
                                  return <p id={contents[x]?.value}>{contents[x]?.value}</p>;
                              }
                            })}
                          </div>
                        ) : (
                          []
                        )}
                        {basicc ? basicc : []}
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </article>{' '}
            </div>
          </div>{' '}
        </div>
      </section>
    </main>
  );
}
