import React, { useEffect, useState } from "react";
import ContentTmpl from "./ContentTmpl";
import { getContent } from "../core/helpers";

export default function PageNot(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent("/assets/error.json").then((x) => {
      setcontent(x?.notfound);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return (
    <ContentTmpl
      leftMenu={[]}
      title={content?.title}
      contents={[{ type: "header1", value: content?.description }]}
    />
  );
}
