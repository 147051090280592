import React, { useEffect, useState } from 'react';
import Carousel from '../components/extra/Carousel';
import { getContent } from '../core/helpers';
import Publications from '../components/extra/Publications';
import HomeNews from '../components/extra/HomeNews';
import ServicesBlk from '../components/extra/ServicesBlk';

export default function Home(props) {
  const [content, setContent] = useState([]);
  const [key, setkey] = useState('homepage');
  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/contents.json').then((x) => {
      console.log(x);
      setContent(x[key]);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  return (
    <>
      <Carousel />
      <section className="container p-3  mt-4">
        <h1 className="container p-3  mt-4 ">{content?.title}</h1>

        {/* <h2 className="blockquote container p-3  mt-4 bg-secondary text-white ">
          {content?.header1}
        </h2> */}
        {/* Services  */}
        <ServicesBlk />
      </section>

      {/* Publications */}
      {/* <Publications /> */}

      <section id="why-pkf" className="bg4 p-3 justify-content-center">
        <div className="container p-3">
          <section className=" row col-md-12 justify-content-center">
            <div className="col-md-6">
              <h2>{content?.header3}</h2>
              <ul className="">
                {content?.services?.length > 0
                  ? Object.keys(content?.services)?.map((x) => {
                      return (
                        <li className="text-start" key={content?.services[x]?.title}>
                          <b>{content?.services[x]?.title}</b>: {content?.services[x]?.description}
                        </li>
                      );
                    })
                  : []}
              </ul>
            </div>
            <div className="col-md-6">
              <img
                src="/assets/pkfQatar/calculator-1680905_1920.png"
                alt="People Around chitchatting"
              />
            </div>
          </section>
        </div>
      </section>

      <section className="container p-3  mt-4">
        <div className="umb-grid text-start" id="gridContent" />
        <div>
          <div className="row clearfix mt-4">
            <div className="col-md-12">
              <div>
                {' '}
                <h2 className="h1">{content?.header2}</h2>
                <p>{content?.para1}</p>
              </div>{' '}
            </div>
          </div>
        </div>
      </section>
      {/* Homepage News block */}
      {/* <HomeNews /> */}
      {/* <div className="text-start blockquote container p-3  mt-4 bg-secondary text-white ">
        <p>{content?.dis1}</p>
        <p>
          <b>{content?.dis2}</b>
        </p>
        <p>{content?.dis3}</p>
      </div> */}
    </>
  );
}
