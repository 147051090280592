import React, { useEffect, useState } from 'react';
import ContentTmpl from './ContentTmpl';
import { getContent } from '../core/helpers';

export default function Contact(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [addressNow, setaddress] = useState([]);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/contactus.json').then((x) => {
      setcontent(x?.contactus);
      const now = x?.contactus;
      let address = [{ type: 'para', value: now?.description }];
      now?.address?.map((x) => {
        address.push({ type: 'para2', value: x });
      });
      setaddress(address);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);

  if (!mounted) return <>Loading...</>;
  return (
    <>
      <ContentTmpl
        leftMenu={[
          { title: 'Career', link: '/career' },
          { title: 'Contact Us', link: '/contact' },
        ]}
        title={content?.title}
        contents={addressNow}
      />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.5355927103233!2d51.52705917583898!3d25.319802626722886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c4c6c3ab189b%3A0x7a4933bc6ed332c4!2sFloor%2015%2C%2061%20Al%20Funduq%20St%2C%20Doha%2C%20Qatar!5e0!3m2!1sen!2sus!4v1696783151111!5m2!1sen!2sus"
        width="100%"
        height="300"
        style={{ border: '0' }}
        allowfullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
}
