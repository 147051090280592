import axios from 'axios';
export const getContent = (url) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(url, {
          crossDomain: true,
        })
        .then((x) => resolve(x?.data))
        .catch((err) => reject(err));
    } catch (error) {
      reject(error);
    }
  });
};
