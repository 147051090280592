import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const routes = [
  { path: "/services", breadcrumb: "Services" },
  { path: "/our-firm", breadcrumb: "Our Firm" },
  { path: "/contact", breadcrumb: "Contact" },
  { path: "/career", breadcrumb: "Career" },
];

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes);
  console.log(breadcrumbs);

  return (
    <nav className="crumbs mt-md-0 mt-sm-4">
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Link key={match.url} to={match.url}>
          {breadcrumb} /
        </Link>
      ))}
    </nav>
  );
}

export default Breadcrumbs;
