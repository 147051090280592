import React, { useEffect, useState } from 'react';
import { getContent } from '../core/helpers';

export default function NavBar(props) {
  const [menuLinks, setMenuLinks] = useState([]);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/nav.json').then((x) => {
      setMenuLinks(x?.links);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  return (
    <>
      {/* <header>
        <div id="topnav">
          <div className="container">
            <select id="Language">
              <option value="/">English</option>
            </select>
            {/* <a
              href="https://twitter.com/"
              className="TwitterWhite Icon"
              title="Follow us on Twitter"
              target="_blank"
            ></a>
            <a
              href="https://www.facebook.com/"
              className="FacebookWhite Icon"
              title="Follow us on Facebook"
              target="_blank"
            ></a> */}
      {/* <a
              href="https://www.linkedin.com/company/pkf-qatar/"
              className="LinkedInWhite Icon"
              title="Follow us on LinkedIn"
              target="_blank"
              rel="noreferrer"
            ></a>
            <a href="/careers" title="Careers">
              Careers
            </a>
            <a href="https://www.linkedin.com/company/pkf-qatar/posts/?feedView=all" title="Events">
              Events
            </a>
          </div>
        </div> */}
      {/* </header> */}
      <section className="ml-auto menu col-md-12 row justify-space-between">
        <div id="LogoBox" className="container col-md-2 d-flex pull-left text-dark p-2 sticky-top">
          <a
            href="/"
            id="Logo"
            className="text-dark p-2"
            alt="PKF Qatar"
            style={{ backgroundImage: "url('/media/logo.png')" }}
          >
            {/* Qatar */}
          </a>
          {/* <span className=" logo">Qatar</span> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="fas fa-bars"></i>
            </span>
          </button>
        </div>

        <nav className="navbar navbar-expand-lg   my-2 my-lg-0 col-md-10 ">
          <div className="container">
            <div className="offcanvas offcanvas-end" id="navbarNav">
              <ul className="navbar-nav justify-content-end">
                {menuLinks?.length > 0
                  ? Object.keys(menuLinks)?.map((x) => {
                      return (
                        <li
                          key={menuLinks[x]?.title + menuLinks[x]?.info + Math.random()}
                          className="nav-item"
                        >
                          <a
                            className="nav-link"
                            alt={menuLinks[x]?.info}
                            href={menuLinks[x]?.link}
                            title={menuLinks[x]?.title}
                          >
                            {menuLinks[x]?.title}
                          </a>
                        </li>
                      );
                    })
                  : []}

                {/* <li className="nav-item">
                <a
                  className="nav-link dropdown-toggle"
                  alt=""
                  href="/"
                  title="Dropdown"
                  id="navbarDropdownMenuLink-services"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <div
                  className="dropdown-menu mega-menu-bg w-100"
                  aria-labelledby="navbarDropdownMenuLink-services"
                >
                  <div className="container">
                    <div className="card-columns">
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-advisory.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>{" "}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-taxlegal.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>{" "}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-assurance.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>
                                <li>
                                  <a
                                    alt=""
                                    href="/"
                                    className=""
                                    title="Menu Item"
                                  >
                                    Menu Item
                                  </a>
                                </li>{" "}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-bussol.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-corpfinance.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-specialisthospitalityconsulting.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="d-none d-lg-block col-md-2 col-lg-2 pl-0 pr-0">
                              <img
                                src="media/icons/mega-nav-icon-allservices.png"
                                alt=""
                              />{" "}
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 pl-2">
                              <ul>
                                <li>
                                  <a alt="" href="/">
                                    <h5>Menu Heading</h5>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              </ul>
            </div>
          </div>
        </nav>
        {/* <div id="LogoBox" className="container">
        <a
          href="/"
          id="Logo"
          style={{ backgroundImage: "url('/media/logo.png')" }}
        >
          PKF - Home
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="fas fa-bars"></i>
          </span>
        </button>
      </div> */}
      </section>
    </>
  );
}
