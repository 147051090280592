import React, { useEffect, useState } from 'react';
import ContentTmpl from './ContentTmpl';
import { getContent } from '../core/helpers';

export default function BizInQatar(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [subMenus, setsubMenus] = useState([]);
  const [templates, settemplates] = useState([]);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/business.json').then((x) => {
      setcontent(x?.biz);
      // start ordering the template design
      let tmp = [],
        menuSet = [];
      x?.biz && x?.biz?.body
        ? Object.values(x?.biz?.body).forEach((y) => {
            tmp.push({ type: 'header', value: y?.title });
            tmp.push({ type: 'para', value: y?.description });
            menuSet.push({ title: y?.title, link: `#${y?.title}` });
          })
        : [];
      settemplates(tmp);
      setsubMenus(menuSet);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return (
    <ContentTmpl
      leftMenu={subMenus}
      title={content?.title}
      desc={content?.description}
      contents={templates}
    />
  );
}
