import React, { useEffect, useState } from 'react';
import { getContent } from '../../core/helpers';

export default function Footer(props) {
  const [menuLinks, setMenuLinks] = useState([]);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/nav.json').then((x) => {
      setMenuLinks(x?.links);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  return (
    <footer className="bg-blue">
      <div className="container">
        <div className="row py-4">
          <div className="col-sm py-2">
            <h3>PKF Qatar</h3>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/pkf-qatar/"
                  target="_blank"
                  rel="noreferrer"
                  title="LinkedIn"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm py-2">
            <h3>Explore Our Site</h3>
            <ul>
              {menuLinks?.length > 0
                ? Object.keys(menuLinks)?.map((x) => {
                    return (
                      <li
                        key={menuLinks[x]?.title + menuLinks[x]?.info + Math.random()}
                        className="nav-item"
                      >
                        <a
                          className="nav-link"
                          alt={menuLinks[x]?.info}
                          href={menuLinks[x]?.link}
                          title={menuLinks[x]?.title}
                        >
                          {menuLinks[x]?.title}
                        </a>
                      </li>
                    );
                  })
                : []}
            </ul>
          </div>
          <div className="col-sm py-2">
            <h3>PKF International</h3>
            <ul>
              <li>
                <a href="https://pkf.com" target="_blank" title="LinkedIn" rel="noreferrer">
                  PKF International
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/company/pkf-international-limited/"
                  target="_blank"
                  title="LinkedIn"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-strap row">
        <div className="container py-1 px-3 col-md-4 border-right">
          {new Date().getFullYear()} All Right Reserved | PKF Qatar | PKF International{' '}
        </div>
        <div className="container py-1 px-3 col-md-8 text-start">
          <p>{`PKF Global is the network of member firms of PKF International Limited, each of which is a separate and independent legal entity and does not accept any responsibility or liability for the actions or inactions of any individual member or correspondent firm(s).`}</p>
          <p>{`'PKF' and the PKF logo are registered trademarks used by PKF International Limited and member firms of the PKF Global Network. They may not be used by anyone other than a duly licensed member firm of the Network.`}</p>
        </div>
      </div>
    </footer>
  );
}
