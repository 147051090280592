import React, { useEffect, useState } from 'react';
import { getContent } from '../core/helpers';

export default function People(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/team.json').then((x) => {
      setcontent(x?.team);
      console.log(x?.team);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col">
                <div className="umb-grid" id="gridContent"></div>
              </div>
            </div>
            <div className="row mt-3" id="PeopleList">
              {Object.keys(content)?.length > 0
                ? Object.keys(content)?.map((x) => {
                    const member = content[x];
                    return (
                      <div className="col-md-12 m-3 p-5 people-block text-start">
                        <div className="row">
                          <div className="col-md-8">
                            <h3 className="mt-5 ">
                              <b>{member?.name}</b>
                            </h3>
                            <h5 className="bold  mb-5">{member?.title}</h5>
                            <p>{member?.bio}</p>
                            <a
                              href={member?.linkedin}
                              className="Icon"
                              title="Follow us on LinkedIn"
                              target="_blank"
                              rel="noreferrer"
                            >
                              LinkedIn
                            </a>
                          </div>
                          <div className="col-md-4">
                            {member?.image ? <img src={member?.image} alt={member?.name} /> : []}
                          </div>
                        </div>

                        <div></div>
                      </div>
                    );
                  })
                : []}
              {/* <div className="col-md-4 people-block">
                <img src="media/default-image-620x600.jpg" alt={member?.name} />
                <h5 className="mt-3">Name Surname</h5>
                <div></div>
                <a className="readmore" href="person.html" title={member?.name}>
                  View Profile
                </a>
              </div> */}
            </div>
            <div className="pagination"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
