import React, { useEffect, useState } from "react";
import { getContent } from "../../core/helpers";

export default function Carousel(props) {
  const [content, setcontent] = useState([]);
  const [active, setActive] = useState("0");

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent("/assets/carousel.json").then((x) => {
      setcontent(x?.carousel);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  return (
    <>
      <div id="Carousel" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          {Object.keys(content)?.map((x, xds) => {
            return (
              <li
                key={content[x]?.title + Math.random()}
                data-target="#Carousel"
                data-slide-to={xds}
                {...(xds === 0 ? { className: "active" } : { className: "" })}
                // onClick={() => setActive(xds)}
              ></li>
            );
          })}
        </ol>
        <div className="carousel-inner">
          {Object.keys(content)?.map((x, xds) => {
            return (
              <div
                key={content[x]?.title + Math.random()}
                {...(xds === 0
                  ? { className: "Blue carousel-item active" }
                  : { className: "Blue carousel-item" })}
              >
                {" "}
                <img
                  className="d-block w-100"
                  src={content[x]?.image}
                  alt={content[x]?.title}
                />
                <div>
                  <h1>{content[x]?.title}</h1>
                  {/* <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2> */}
                  <h2>{content[x]?.description}</h2>
                  {content[x]?.link ? (
                    <a href={content[x]?.link}>Click Here</a>
                  ) : (
                    []
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <a
          className="carousel-control-prev"
          role="button"
          href="#Carousel"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          role="button"
          href="#Carousel"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
}
