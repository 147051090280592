import React, { useEffect, useState } from "react";
import ContentTmpl from "./ContentTmpl";
import { getContent } from "../core/helpers";

export default function Careers(props) {
  const [content, setcontent] = useState([]);
  const [mounted, setMounted] = useState(false);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent("/assets/contactus.json").then((x) => {
      setcontent(x?.career);
      setMounted(true);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  if (!mounted) return <>Loading...</>;
  return (
    <ContentTmpl
      leftMenu={[
        { title: "Career", link: "/career" },
        { title: "Contact Us", link: "/contact" },
      ]}
      title={content?.title}
      contents={[{ type: "para", value: content?.description }]}
    />
  );
}
