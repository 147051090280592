import React, { useEffect, useState } from "react";
import { getContent } from "../../core/helpers";

export default function ServicesBlk(props) {
  const [content, setcontent] = useState([]);
  const [active, setActive] = useState("");

  // Page convertor

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent("/assets/services.json").then((x) => {
      setcontent(x);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  return (
    <section id="iconstrip" className="container ">
      <div className="row mt-5 mb-5">
        {" "}
        <h3>Our Services</h3>
        {Object.keys(content)?.map((x) => {
          return (
            <div key={x} className="col-4 col-md mb-3">
              <a
                href={`/services#${content[x]?.title}`}
                title={content[x]?.title}
              >
                <img src={content[x]?.icon} /> {content[x]?.title}
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
}
