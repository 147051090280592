import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/extra/Footer";
import ServicesPage from "./pages/ServicesPage";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import PageNot from "./pages/PageNot";
import OurFirm from "./pages/OurFirm";
import OurTeam from "./pages/OurTeam";
import BizInQatar from "./pages/BizInQatar";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-firm" element={<OurFirm />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/our-team/:team" element={<OurTeam />} />
            <Route path="/career" element={<Careers />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/doing-business-in-qatar" element={<BizInQatar />} />
            <Route path="/business-in-qatar" element={<BizInQatar />} />
            <Route path="/*" element={<PageNot />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
